'use strict';

window.ranger = window.ranger || {};

/**
 * Handles the Home and Feed UI.
 */
ranger.Category = class {
  constructor() {

    // Firebase SDK.
    this.auth = firebase.auth();
    this.database = firebase.database();

    //Category Selectors


    $(document).ready(() => {
      $('.sortCategoryList').on('click', '.btnCategorySort', function() {
        if($(this).data('id') === 'all') {
          ranger.feed.showHomeFeed();
        } else {
          ranger.feed.showCategoryFeed($(this).data('id'));
        }
      })
      $('.sortCategoryList').on('click', '.categoryDelete', function() {
        swal({
          title: 'Are you sure?',
          text: 'You will not be able to recover this category!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, delete it!',
          closeOnConfirm: false,
          showLoaderOnConfirm: true,
          allowEscapeKey: true
        }, () => {
          ranger.category.deleteCategory($(this).data('id')).then(() => {

            swal({
              title: 'Deleted!',
              text: 'Category has been deleted.',
              type: 'success',
              timer: 2000
            });
          }).catch(error => {
            swal.close();
            const data = {
              message: `There was an error deleting your category: ${error}`,
              timeout: 5000
            };
            returnToast(data,8000);
          });
        });
      })

      $('body').on('click', '.fp-setCategory', function() {
        ranger.range.stopListeners();
        $('#categoriesForm').modal('show');
        window.postKey = $(this).data('id');
        ranger.range.startListeners();
      });

      $('.postCategories').on('click', 'button', function() {
        ranger.range.savePostCategory($(this).attr('id'), $(this).data('name'));
      });

      $('.categoryChips').on('click', 'span', function() {
        ranger.range.removePostCategory($(this).data('id'));
        $(this).remove();
      });
    })
  }
  startCategoryListeners() {
    $('.sortCategoryList').empty();
    $('.sortCategoryList').append(`<a data-id="all" class="dropdown-item btnCategorySort">Show All</a>`);
    $('#sortCategories').show();
    this.database.ref(`/people/${this.auth.currentUser.uid}/categories`).on('child_added', categoryData => {
        var html = `<div data-selector="${categoryData.key}" class="btn-group dropdown-item" role="group"><a data-id="${categoryData.key}" class="btn btn-warning btnCategorySort">${categoryData.val()}</a>
        <a data-id="${categoryData.key}" class="btn btn-warning categoryDelete"><i class="material-icons">delete</a></div>`;
        $('.sortCategoryList').append(html);
        var htmltwo = `<button id="${categoryData.key}" data-name="${categoryData.val()}" class="btn btn-success">${categoryData.val()}</button>`;
        $('.postCategories').append(htmltwo);
    });
    /*this.database.ref(`/people/${this.auth.currentUser.uid}/categories`).on('child_removed', categoryData => {
        alert(categoryData.key);

    });*/
  }
  deleteCategory(categoryId) {
    var deletes = {};

    deletes[`/people/${this.auth.currentUser.uid}/categories/${categoryId}`] = null;
    deletes[`/people/${this.auth.currentUser.uid}/categoryFeed/${categoryId}`] = null;

    return this.database.ref().update(deletes);
  }
}

ranger.category = new ranger.Category;
